import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    forestGreen: "#15568f",
    darkFern: "#497cab",
    gold: "#ffd700",
    mineShaft: "#292929",
    codGray: "#1a1a1a",
    tundora: "#484848",
    cararra: "#f4f4f1",
    shark: "#1e1f1f",
    supernova: "#f0cb00",
    corn: "#e0bd00",
    internationalOrange: "#f75500",
  },

  fonts: {
    korolevCompressed: "korolev-compressed, sans-serif",
  },

  fontSizes: {
    "7xl": "5rem",
  },

  lineHeights: {
    base: 1.75,
  },

  radii: {
    xl: "1.25rem",
  },

  shadows: {
    default: "0 3px 24px rgba(0, 0, 0, 0.05)",
  },

  textStyles: {
    korolevCompressed: {
      fontFamily: "korolevCompressed",
      textTransform: "uppercase",
      letterSpacing: "0.05em",
      lineHeight: "none",
    },
  },
});

export default theme;
