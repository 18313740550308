import React, { createContext, FC, useRef } from "react";
import {
  Box,
  Code,
  Heading,
  StackDivider,
  VStack,
  HStack,
  Button,
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import {
  MapContext,
  MapEvent,
  mapMachine,
  MapStateSchema,
} from "../machines/map";
import { Machine } from "../types";
import { Map } from "./map";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Nav } from "./nav";
import "focus-visible/dist/focus-visible";
import { PageProps } from "gatsby";

type LayoutContext = {
  mapMachine: Machine<MapContext, MapStateSchema, MapEvent>;
};

export const LayoutContext = createContext<Partial<LayoutContext>>({});

const Layout: FC<PageProps> = props => {
  const [mapState, mapSend, mapService] = useMachine(mapMachine, {
    devTools: true,
    immediate: true,
    actions: {
      offsetVanishingPoint: () => {
        // if (mapRef.current) {
        //   mapRef.current.state.map.easeTo({
        //     padding: {
        //       right: 1000,
        //     },
        //     duration: 300,
        //   });
        // }
      },

      restoreVanishingPoint: () => {
        // if (mapRef.current) {
        //   mapRef.current.state.map.easeTo({
        //     padding: {
        //       right: 0,
        //     },
        //     duration: 300,
        //   });
        // }
      },
    },
  });

  const { children } = props;

  return (
    <LayoutContext.Provider
      value={{
        mapMachine: [mapState, mapSend, mapService],
      }}
    >
      <Helmet title="Wonen in de Delftlanden | Koenen Bouw">
        <link rel="stylesheet" href="https://use.typekit.net/njm8wkz.css" />

        <script>
          {`(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2137411,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </Helmet>

      <Box
        as="header"
        position="absolute"
        w="full"
        pointerEvents="none"
        zIndex={2}
      >
        <Nav />
      </Box>

      <Map />

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => {
          window.scrollTo({ top: 0 });
        }}
      >
        {children}
      </AnimatePresence>

      {/*<Box*/}
      {/*  position="fixed"*/}
      {/*  bottom={8}*/}
      {/*  right={8}*/}
      {/*  rounded="lg"*/}
      {/*  bgColor="white"*/}
      {/*  shadow="2xl"*/}
      {/*  overflowY="auto"*/}
      {/*  w={300}*/}
      {/*  h={400}*/}
      {/*  borderTopWidth="1rem"*/}
      {/*  borderBottomWidth="1rem"*/}
      {/*  borderLeftWidth="1.5rem"*/}
      {/*  borderRightWidth="1.5rem"*/}
      {/*  borderColor="white"*/}
      {/*  opacity={0.5}*/}
      {/*  _hover={{ opacity: 1 }}*/}
      {/*  transition="all .1s ease-in-out"*/}
      {/*  zIndex={999}*/}
      {/*>*/}
      {/*  <VStack*/}
      {/*    align="left"*/}
      {/*    spacing={6}*/}
      {/*    divider={<StackDivider borderColor="gray.400" />}*/}
      {/*  >*/}
      {/*    <VStack align="left" spacing={4}>*/}
      {/*      <Heading size="sm">toestand</Heading>*/}
      {/*      <Code p={3} overflowX="auto">*/}
      {/*        <pre>{JSON.stringify(mapState.value, null, 2)}</pre>*/}
      {/*      </Code>*/}
      {/*    </VStack>*/}

      {/*    <VStack align="left" spacing={4}>*/}
      {/*      <Heading size="sm">context</Heading>*/}
      {/*      <Code p={3} overflowX="auto">*/}
      {/*        <pre>{JSON.stringify(mapState.context, null, 2)}</pre>*/}
      {/*      </Code>*/}
      {/*    </VStack>*/}

      {/*    <VStack align="left" spacing={4}>*/}
      {/*      <Heading size="sm">volgende events</Heading>*/}
      {/*      <VStack align="left">*/}
      {/*        {mapState.nextEvents.map((event, i) => (*/}
      {/*          <div key={i}>{event}</div>*/}
      {/*        ))}*/}
      {/*      </VStack>*/}
      {/*    </VStack>*/}
      {/*  </VStack>*/}
      {/*</Box>*/}
    </LayoutContext.Provider>
  );
};

export default Layout;
