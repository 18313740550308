import React, {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import ReactMapboxGl, { Layer, Marker, Source, Popup } from "react-mapbox-gl";
import { mapboxAccessToken, mapViewTransitionDurationInMs } from "../constants";
import { graphql, Link as GatsbyLink, useStaticQuery, navigate } from "gatsby";
import { MapQuery } from "../../graphql-types";
import { AnimatePresence, motion } from "framer-motion";
import { LayoutContext } from "./layout";
import ProjectMarker from "../assets/svg/project-marker.inline.svg";
import {
  Box,
  CloseButton,
  Heading,
  Stack,
  Text,
  Link as ChakraLink,
  StackDivider,
  Icon,
  Tag,
} from "@chakra-ui/react";
import Img, { FluidObject } from "gatsby-image";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { center as turfCenter } from "@turf/turf";
import KoenenBouw from "../assets/svg/koenen-bouw.inline.svg";
import Project from "../assets/svg/project.inline.svg";
import Lot from "../assets/svg/lot.inline.svg";
import Rooms from "../assets/svg/rooms.inline.svg";
import House from "../assets/svg/house.inline.svg";

const MapboxGl = ReactMapboxGl({
  accessToken: mapboxAccessToken,
  bearingSnap: 0,
  // pitchWithRotate: false,
  // dragRotate: false,
  boxZoom: false,
  antialias: true,
});

const formatter = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR",
});

export const Map: FC = () => {
  const mapRef = useRef<any>();

  /**
   * Queried data
   */

  const data = useStaticQuery<MapQuery>(graphql`
    query Map {
      wp {
        globaal {
          globalCustomFields {
            styleUrl

            overview {
              tilesUrl
              center {
                latitude
                longitude
              }
              bearing
              pitch
              zoom
              maxBounds {
                southWest {
                  latitude
                  longitude
                }
                northEast {
                  latitude
                  longitude
                }
              }
            }
          }
        }
      }

      allWpProject {
        edges {
          node {
            id
            title
            uri
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }

            projectCustomFields {
              marker {
                latitude
                longitude
              }

              view {
                center {
                  latitude
                  longitude
                }
                bearing
                fieldGroupName
                pitch
                zoom
                tilesUrlLots
              }
            }
          }
        }
      }

      allWpLot {
        edges {
          node {
            id
            uri
            lotCustomFields {
              type
              state
              id
              price {
                price
                priceSuffix
              }
              project {
                ... on WpProject {
                  id
                }
              }
              livingArea
              lotArea
              numberOfRooms
              houseType {
                ... on WpHouseType {
                  title
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
              geojsonFeatureLot
            }
          }
        }
      }
    }
  `);

  /**
   * Values derived from queried data
   */

  const overview = data.wp?.globaal?.globalCustomFields?.overview;
  const projects = data.allWpProject.edges;
  const lots = data.allWpLot.edges;

  const overviewTileJsonSource = useRef({
    type: "raster",
    tileSize: 512,
    tiles: [data.wp?.globaal?.globalCustomFields?.overview?.tilesUrl],
  });

  const projectTileJsonSources = useRef(
    projects.map(edge => ({
      id: edge.node.id,
      source: {
        type: "raster",
        tileSize: 512,
        tiles: [edge.node.projectCustomFields?.view?.tilesUrlLots],
      },
    }))
  );

  const lotGeoJsonSources = useRef(
    lots
      .filter(
        edge =>
          edge.node.lotCustomFields?.geojsonFeatureLot &&
          edge.node.lotCustomFields?.geojsonFeatureLot?.trim() !== ""
      )
      .map(edge => ({
        id: edge.node.id,
        projectId: edge.node.lotCustomFields?.project?.id,
        lot: edge,
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              JSON.parse(edge.node.lotCustomFields?.geojsonFeatureLot || "{}"),
            ],
          },
        },
      }))
  );

  /**
   * Local state
   */

  const [center, setCenter] = useState<[number, number]>();
  const [pitch, setPitch] = useState<[number]>();
  const [bearing, setBearing] = useState<[number]>();
  const [zoom, setZoom] = useState<[number]>();

  /**
   * Machine state
   */

  const { mapMachine } = useContext(LayoutContext);
  const [mapState, mapSend] = mapMachine as NonNullable<typeof mapMachine>;

  /**
   * Values derived from machine state
   */

  const mapIsMounted = mapState.matches({ mounted: "mounted" });

  const mapIsVisible = mapState.matches({ mounted: { mounted: "visible" } });

  const currentViewIsOverview =
    mapState.matches({ view: "overview" }) ||
    mapState.matches({ view: "transitioningToOverview" });

  const currentViewIsProject =
    mapState.matches({ view: "project" }) ||
    mapState.matches({ view: "transitioningToProject" });

  const isTransitioning =
    mapState.matches({ view: "transitioningToProject" }) ||
    mapState.matches({ view: "transitioningToOverview" });

  const someProjectPopupIsActive = mapState.matches({
    view: { overview: "projectPopup" },
  });

  const someLotIsSelected = mapState.matches({
    view: { project: "lotSelected" },
  });

  /**
   * Values derived from both view queried data and machine state
   */

  const activeProjectView = currentViewIsProject
    ? projects.find(edge => edge.node.id === mapState.context.activeProjectId)
        ?.node.projectCustomFields?.view
    : undefined;

  const activeProjectPopup = someProjectPopupIsActive
    ? projects.find(
        edge => edge.node.id === mapState.context.activeProjectPopupId
      )?.node
    : undefined;

  const selectedLot = someLotIsSelected
    ? lots.find(edge => edge.node.id === mapState.context.selectedLotId)?.node
    : undefined;

  /**
   * Update view state once user navigates to overview
   */

  useEffect(() => {
    if (mapState.event.type === "GO_TO_OVERVIEW") {
      setCenter([overview?.center?.longitude, overview?.center?.latitude] as [
        number,
        number
      ]);
      setPitch([overview?.pitch] as [number]);
      setBearing([overview?.bearing] as [number]);
      setZoom([overview?.zoom] as [number]);
    }
  }, [mapState.event.type]);

  /**
   * Update view state once user navigates to project view
   */

  useEffect(() => {
    if (mapState.event.type === "GO_TO_PROJECT" && activeProjectView) {
      setCenter([
        activeProjectView.center?.longitude,
        activeProjectView.center?.latitude,
      ] as [number, number]);
      setPitch([activeProjectView.pitch] as [number]);
      setBearing([activeProjectView.bearing] as [number]);
      setZoom([activeProjectView.zoom] as [number]);
    }
  }, [mapState.event.type]);

  /**
   * Effects triggered during each render (for dev purposes)
   */

  useEffect(() => {
    console.log("render");
  });

  return (
    <>
      {isTransitioning && (
        // this overlay prevents user interactions during transitions between views
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 0,
          }}
        />
      )}

      <motion.div
        animate={{
          x: mapIsVisible ? "0%" : "-100%",
          transition: { duration: 0.5 },
        }}
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -1,
        }}
      >
        {mapIsMounted && center && pitch && bearing && zoom && (
          <MapboxGl
            ref={mapRef}
            renderChildrenInPortal={true}
            style={data.wp?.globaal?.globalCustomFields?.styleUrl as string}
            center={center}
            pitch={pitch}
            bearing={bearing}
            zoom={zoom}

            onMouseMove={(map, e: any) => {
              const lotsUnderPointer = map
                .queryRenderedFeatures(e.point)
                .filter(feature =>
                  feature.layer.id.startsWith("lot-geojson-interactive-layer-")
                ).length;

              if (
                mapState.matches({
                  view: { project: "lotSelected" },
                }) &&
                lotsUnderPointer === 0
              ) {
                mapSend("DESELECT_LOT");
                e.target.getCanvas().style.cursor = "default";
              }
            }}
            containerStyle={{
              height: "100%",
              width: "100%",
            }}
            onMoveEnd={map => {
              console.log(
                JSON.stringify({
                  center: map.getCenter(),
                  zoom: map.getZoom(),
                  bearing: map.getBearing(),
                  pitch: map.getPitch(),
                })
              );
            }}
            flyToOptions={{
              // @ts-ignore
              duration: mapViewTransitionDurationInMs,
            }}
            onClick={() => {
              console.log("click: map");

              // if (currentViewIsOverview && projectPopupIsActive) {
              //   mapSend("DESELECT_PROJECT_POPUP");
              // }
            }}
          >
            {/* overview tiles */}
            <Source
              id="overview-tiles-source"
              tileJsonSource={overviewTileJsonSource.current}
            />

            <Layer
              id="overview-tiles-layer"
              type="raster"
              sourceId="overview-tiles-source"
              paint={{
                "raster-brightness-max": 0.7,
                "raster-contrast": 0.1,
              }}
            />

            {/* overview: project markers */}
            <AnimatePresence>
              {currentViewIsOverview &&
                projects.map(edge => (
                  <motion.div
                    key={edge.node.id}
                    initial={{ opacity: 0, y: -200 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: mapViewTransitionDurationInMs / 1000 / 3.5,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      y: -200,
                      transition: {
                        delay: mapViewTransitionDurationInMs / 1000 / 1.75,
                      },
                    }}
                  >
                    <Marker
                      anchor="bottom"
                      coordinates={[
                        edge.node.projectCustomFields?.marker
                          ?.longitude as number,
                        edge.node.projectCustomFields?.marker
                          ?.latitude as number,
                      ]}
                      onMouseEnter={() => {
                        setTimeout(() => {
                          mapSend("SHOW_PROJECT_POPUP", {
                            projectId: edge.node.id,
                          });
                        });
                      }}
                      onMouseLeave={() => {
                        mapSend("HIDE_PROJECT_POPUP");
                      }}
                    >
                      <>
                        {/* popup */}
                        {activeProjectPopup?.id === edge.node.id && (
                          <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{
                              opacity: 0,
                              y: 30,
                            }}
                            style={{
                              position: "absolute",
                              bottom: "100%",
                              right: "50%",
                              x: "50%",
                              border: "1rem solid transparent",
                            }}
                          >
                            <Box
                              minW={64}
                              bgColor="white"
                              borderRadius="xl"
                              overflow="hidden"
                            >
                              <CloseButton
                                onClick={() => mapSend("HIDE_PROJECT_POPUP")}
                                position="absolute"
                                color="white"
                                zIndex={2}
                                right={1}
                                top={3}
                              />

                              <GatsbyLink to={activeProjectPopup.uri}>
                                <Img
                                  fluid={{
                                    ...(activeProjectPopup.featuredImage?.node
                                      ?.localFile?.childImageSharp
                                      ?.fluid as FluidObject),
                                    aspectRatio: 16 / 9,
                                  }}
                                  loading="eager"
                                />

                                <Stack px={5} pt={5} pb={6}>
                                  <Heading
                                    textStyle="korolevCompressed"
                                    fontSize="2xl"
                                  >
                                    {activeProjectPopup.title}
                                  </Heading>

                                  <Stack direction="row" align="center">
                                    <Icon
                                      as={Project}
                                      color="forestGreen"
                                      boxSize={5}
                                    />
                                    <Text color="tundora" fontSize="sm">
                                      {
                                        lots.filter(edge => {
                                          const belongsToProject =
                                            edge.node.lotCustomFields?.project
                                              ?.id ===
                                            mapState.context
                                              .activeProjectPopupId;

                                          const isAvailable =
                                            edge.node.lotCustomFields?.state ===
                                            "available";

                                          return (
                                            belongsToProject && isAvailable
                                          );
                                        }).length
                                      }{" "}
                                      beschikbare kavels
                                    </Text>
                                  </Stack>
                                </Stack>

                                <ArrowForwardIcon
                                  position="absolute"
                                  right={4}
                                  bottom={3}
                                  boxSize={3}
                                />
                              </GatsbyLink>
                            </Box>
                          </motion.div>
                        )}

                        {/* marker */}
                        <div style={{ position: "relative", zIndex: 2 }}>
                          {activeProjectPopup ? (
                            <GatsbyLink to={edge.node.uri}>
                              <ProjectMarker />
                            </GatsbyLink>
                          ) : (
                            <ProjectMarker />
                          )}
                        </div>
                      </>
                    </Marker>
                  </motion.div>
                ))}
            </AnimatePresence>

            {/* project tiles */}
            {projectTileJsonSources.current.map(project => (
              <Fragment key={project.id}>
                <Source
                  id={`project-tiles-source-${project.id}`}
                  tileJsonSource={project.source}
                />

                <Layer
                  id={`project-tiles-layer-${project.id}`}
                  type="raster"
                  sourceId={`project-tiles-source-${project.id}`}
                  paint={{
                    "raster-brightness-max": 0.7,
                    "raster-contrast": 0.1,
                  }}
                  layout={{
                    visibility:
                      currentViewIsProject &&
                      mapState.context.activeProjectId === project.id
                        ? "visible"
                        : "none",
                  }}
                />
              </Fragment>
            ))}

            {/* project lots */}
            {lotGeoJsonSources.current.map(lot => {
              if (
                currentViewIsProject &&
                lot.projectId === mapState.context.activeProjectId
              ) {
                return (
                  <Fragment key={lot.id}>
                    {/* source */}
                    <Source
                      id={`lot-geojson-source-${lot.id}`}
                      geoJsonSource={lot.source}
                    />

                    {/* outline layer */}
                    <Layer
                      id={`lot-geojson-outline-layer-${lot.id}`}
                      type="line"
                      sourceId={`lot-geojson-source-${lot.id}`}
                      paint={{
                        "line-color": "#000",
                        "line-opacity": 0.5,
                        "line-width": 3,
                      }}
                    />

                    {/* overlay layer */}
                    <Layer
                      id={`lot-geojson-overlay-layer-${lot.id}`}
                      type="fill"
                      sourceId={`lot-geojson-source-${lot.id}`}
                      paint={{
                        // available
                        "fill-color":
                          (lot.lot.node.lotCustomFields?.state ===
                            "available" &&
                            "green") ||
                          // sold
                          (lot.lot.node.lotCustomFields?.state === "sold" &&
                            "red") ||
                          // under option
                          (lot.lot.node.lotCustomFields?.state ===
                            "underOption" &&
                            "yellow") ||
                          // under development
                          (lot.lot.node.lotCustomFields?.state ===
                            "underDevelopment" &&
                            "blue"),
                        "fill-opacity": 0.2,
                      }}
                    />

                    {/* visual layer */}
                    <Layer
                      id={`lot-geojson-visual-layer-${lot.id}`}
                      type="fill-extrusion"
                      sourceId={`lot-geojson-source-${lot.id}`}
                      paint={{
                        "fill-extrusion-color": "#fff",
                        "fill-extrusion-height":
                          selectedLot?.id === lot.id ? 10 : 0,
                        "fill-extrusion-height-transition": { duration: 125 },
                        "fill-extrusion-opacity":
                          selectedLot?.id === lot.id ? 0.5 : 0,
                        "fill-extrusion-opacity-transition": { duration: 125 },
                      }}
                    />

                    {/* interactive layer */}
                    <Layer
                      id={`lot-geojson-interactive-layer-${lot.id}`}
                      type="fill-extrusion"
                      sourceId={`lot-geojson-source-${lot.id}`}
                      paint={{
                        "fill-extrusion-height": 10,
                        "fill-extrusion-opacity": 0,
                      }}
                      layout={{
                        visibility:
                          (currentViewIsProject && !someLotIsSelected) ||
                          (someLotIsSelected &&
                            mapState.context.selectedLotId === lot.id)
                            ? "visible"
                            : "none",
                      }}
                      onMouseEnter={(e: any) => {
                        mapSend("SELECT_LOT", { lotId: lot.id });
                        e.target.getCanvas().style.cursor = "pointer";
                      }}
                      onClick={() => {
                        navigate(lot.lot.node.uri);
                      }}
                    />
                  </Fragment>
                );
              }
            })}

            <AnimatePresence>
              {selectedLot && (
                <motion.div
                  key={selectedLot.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{
                    opacity: 0,
                  }}
                >
                  <Box
                    sx={{
                      ".mapboxgl-popup-tip": {
                        display: "none",
                      },

                      ".mapboxgl-popup-content": {
                        padding: 0,
                        boxShadow: "default",
                        borderRadius: "xl",
                        overflow: "hidden",
                      },
                    }}
                    position="relative"
                  >
                    <Popup
                      anchor="bottom"
                      coordinates={
                        turfCenter(
                          JSON.parse(
                            selectedLot.lotCustomFields?.geojsonFeatureLot ||
                              "{}"
                          )
                        ).geometry?.coordinates as number[]
                      }
                    >
                      <Box
                        minW={64}
                        bgColor="white"
                        borderRadius="xl"
                        overflow="hidden"
                      >
                        <CloseButton
                          onClick={() => mapSend("DESELECT_LOT")}
                          position="absolute"
                          color="white"
                          zIndex={2}
                          right={1}
                          top={3}
                        />

                        <GatsbyLink to={selectedLot.uri}>
                          <Img
                            fluid={{
                              ...(selectedLot.lotCustomFields?.houseType
                                ?.featuredImage?.node?.localFile
                                ?.childImageSharp?.fluid as FluidObject),
                              aspectRatio: 16 / 9,
                            }}
                            loading="eager"
                          />

                          <Stack px={5} pt={5} pb={6} spacing={3}>
                            <Heading
                              textStyle="korolevCompressed"
                              fontSize="md"
                              color="forestGreen"
                            >
                              Bouwnummer {selectedLot.lotCustomFields?.id}
                            </Heading>

                            <Heading
                              textStyle="korolevCompressed"
                              fontSize="2xl"
                            >
                              {selectedLot.lotCustomFields?.houseType?.title}
                            </Heading>

                            {selectedLot.lotCustomFields?.price?.price &&
                              selectedLot.lotCustomFields?.type ===
                                "houseSale" &&
                              selectedLot.lotCustomFields?.state !== "sold" && (
                                <Box
                                  fontSize="xl"
                                  textStyle="korolevCompressed"
                                >
                                  {formatter.format(
                                    selectedLot.lotCustomFields?.price?.price ||
                                      0
                                  )}{" "}
                                  <Box as="span" fontSize="sm">
                                    {
                                      selectedLot.lotCustomFields?.price
                                        ?.priceSuffix
                                    }
                                  </Box>
                                </Box>
                              )}

                            <Tag
                              alignSelf="start"
                              borderRadius="xl"
                              bgColor={
                                // available
                                (selectedLot.lotCustomFields?.state ===
                                  "available" &&
                                  "forestGreen") ||
                                // sold
                                (selectedLot.lotCustomFields?.state ===
                                  "sold" &&
                                  "internationalOrange") ||
                                // under option
                                (selectedLot.lotCustomFields?.state ===
                                  "underOption" &&
                                  "gold") ||
                                // under development
                                "facebook.600"
                              }
                              color={
                                // available
                                (selectedLot.lotCustomFields?.state ===
                                  "available" &&
                                  "white") ||
                                // sold
                                (selectedLot.lotCustomFields?.state ===
                                  "sold" &&
                                  "white") ||
                                // under option
                                (selectedLot.lotCustomFields?.state ===
                                  "underOption" &&
                                  "black") ||
                                // under development
                                "white"
                              }
                            >
                              {
                                // available
                                (selectedLot.lotCustomFields?.state ===
                                  "available" &&
                                  "beschikbaar") ||
                                  // sold
                                  (selectedLot.lotCustomFields?.state ===
                                    "sold" &&
                                    "verkocht") ||
                                  // under option
                                  (selectedLot.lotCustomFields?.state ===
                                    "underOption" &&
                                    "in optie") ||
                                  // under development
                                  (selectedLot.lotCustomFields?.state ===
                                    "underDevelopment" &&
                                    "in ontwikkeling")
                              }
                            </Tag>

                            <Stack
                              direction="row"
                              divider={<StackDivider />}
                              spacing={4}
                            >
                              <Stack>
                                <Icon
                                  as={House}
                                  boxSize={5}
                                  color="forestGreen"
                                />

                                <Heading
                                  size="xs"
                                  color="tundora"
                                  fontWeight="semibold"
                                >
                                  Wonen
                                </Heading>

                                <Text color="tundora" fontSize="sm">
                                  {selectedLot.lotCustomFields?.livingArea} m
                                  <sup>2</sup>
                                </Text>
                              </Stack>

                              <Stack>
                                <Icon
                                  as={Rooms}
                                  boxSize={5}
                                  color="forestGreen"
                                />

                                <Heading
                                  size="xs"
                                  color="tundora"
                                  fontWeight="semibold"
                                >
                                  Kamers
                                </Heading>

                                <Text color="tundora">
                                  {selectedLot.lotCustomFields?.numberOfRooms}
                                </Text>
                              </Stack>

                              <Stack>
                                <Icon
                                  as={Lot}
                                  boxSize={5}
                                  color="forestGreen"
                                />

                                <Heading
                                  size="xs"
                                  color="tundora"
                                  fontWeight="semibold"
                                >
                                  Perceel
                                </Heading>

                                <Text color="tundora">
                                  {selectedLot.lotCustomFields?.lotArea} m
                                  <sup>2</sup>
                                </Text>
                              </Stack>
                            </Stack>
                          </Stack>

                          <ArrowForwardIcon
                            position="absolute"
                            right={4}
                            bottom={3}
                            boxSize={3}
                          />
                        </GatsbyLink>
                      </Box>
                    </Popup>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
          </MapboxGl>
        )}

        <ChakraLink
          href="https://www.koenenbouwemmen.nl/"
          isExternal={true}
          position="fixed"
          right={8}
          bottom={12}
          color="white"
          display={{ base: "none", md: "block" }}
          transition="all .1s ease-in-out"
          _hover={{
            transform: "translateY(-5px)",
          }}
        >
          <KoenenBouw />
        </ChakraLink>
      </motion.div>
    </>
  );
};
