// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-lot-index-tsx": () => import("./../../../src/templates/lot/index.tsx" /* webpackChunkName: "component---src-templates-lot-index-tsx" */),
  "component---src-templates-overview-index-tsx": () => import("./../../../src/templates/overview/index.tsx" /* webpackChunkName: "component---src-templates-overview-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../../../src/templates/post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-posts-index-tsx": () => import("./../../../src/templates/posts/index.tsx" /* webpackChunkName: "component---src-templates-posts-index-tsx" */),
  "component---src-templates-project-index-tsx": () => import("./../../../src/templates/project/index.tsx" /* webpackChunkName: "component---src-templates-project-index-tsx" */)
}

