import React, { FC, useEffect, useState } from "react";
import { Box, Flex, Spacer, Stack, Button } from "@chakra-ui/react";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import { NarrowNavQuery } from "../../../graphql-types";
import { useLocation } from "@reach/router";
import Logo from "../../assets/svg/logo.inline.svg";
import { Container } from "../container";
import { AnimatePresence, motion } from "framer-motion";
import { Sling as Hamburger } from "hamburger-react";

/**
 * Narrow
 */
export const Narrow: FC = () => {
  const data = useStaticQuery<NarrowNavQuery>(graphql`
    query NarrowNav {
      wpMenu(locations: { eq: PRIMARY }) {
        menuItems {
          nodes {
            id
            parentId
            label
            url
          }
        }
      }
    }
  `);

  const location = useLocation();

  const [isExpanded, setIsExpanded] = useState(true);
  const [parentItemId, setParentItemId] = useState<string>();

  const menuItems = data.wpMenu?.menuItems?.nodes?.filter(
    node => node?.parentId == parentItemId
  );

  useEffect(() => {
    setParentItemId(null);
    setIsExpanded(false);
  }, [location.pathname]);

  return (
    <>
      {/* menu */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            key="nav"
            initial={{ y: "-100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "-100%" }}
            style={{ position: "fixed", height: "100vh", width: "100vw" }}
            transition={{ duration: 0.2 }}
          >
            <Box
              bgColor="forestGreen"
              h="full"
              color="white"
              pointerEvents="all"
            >
              <Container py={32}>
                <Stack spacing={8}>
                  {menuItems?.map(node => {
                    if (
                      data.wpMenu?.menuItems?.nodes?.filter(
                        childNode => childNode?.parentId === node?.id
                      )?.length
                    ) {
                      return (
                        <Flex
                          key={node?.url}
                          textStyle="korolevCompressed"
                          fontSize="3xl"
                          onClick={() => setParentItemId(node?.id)}
                        >
                          <Box>{node?.label}</Box>
                          <Spacer />
                          <Box>&rarr;</Box>
                        </Flex>
                      );
                    } else {
                      return (
                        <GatsbyLink key={node?.id} to={node?.url || ""}>
                          <Box textStyle="korolevCompressed" fontSize="3xl">
                            {node?.label}
                          </Box>
                        </GatsbyLink>
                      );
                    }
                  })}
                </Stack>
              </Container>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>

      {/* logo & toggler */}
      <Container py={3} color="white" position="relative" maxW="xl">
        <Flex align="baseline">
          <GatsbyLink to="/" style={{ pointerEvents: "all" }}>
            <Logo style={{ height: "2rem", width: "auto" }} />
          </GatsbyLink>

          <Spacer />

          <Box
            pointerEvents="all"
            onClick={() => {
              setIsExpanded(prev => !prev);
            }}
            color={isExpanded ? "gold" : "inherit"}
          >
            <Hamburger toggled={isExpanded} />
          </Box>
        </Flex>
      </Container>
    </>
  );
};
