import { assign, Machine } from "xstate";
import { mapViewTransitionDurationInMs } from "../constants";

export type MapContext = {
  activeProjectPopupId?: string;
  activeProjectId?: string;
  selectedLotId?: string;
};

export type MapEvent =
  | { type: "GO_TO_OVERVIEW" }
  | { type: "GO_TO_PROJECT"; projectId: string }
  | { type: "HIDE_MAP" }
  | { type: "SHOW_PROJECT_POPUP"; projectId: string }
  | { type: "HIDE_PROJECT_POPUP" }
  | { type: "SELECT_LOT"; lotId: string }
  | { type: "DESELECT_LOT" };

export type MapStateSchema = {
  states: {
    mounted: {
      states: {
        notMounted: {};
        mounted: {
          states: {
            visible: {};
            hidden: {};
          };
        };
      };
    };
    view: {
      states: {
        init: {};
        overview: {
          states: {
            idle: {};
            projectPopup: {};
          };
        };
        transitioningToProject: {};
        transitioningToOverview: {};
        project: {
          states: {
            noLotSelected: {};
            lotSelected: {};
          };
        };
      };
    };
  };
};

export const mapMachine = Machine<MapContext, MapStateSchema, MapEvent>(
  {
    strict: true,
    id: "map",
    context: {
      activeProjectPopupId: undefined,
      activeProjectId: undefined,
      selectedLotId: undefined,
    },
    on: {
      GO_TO_OVERVIEW: {
        target: ["mounted.mounted.visible", "view.overview"],
      },

      GO_TO_PROJECT: {
        target: ["mounted.mounted.visible", "view.project"],
        actions: "selectProject",
      },
    },
    type: "parallel",
    states: {
      mounted: {
        id: "mounted",
        initial: "notMounted",
        states: {
          notMounted: {},
          mounted: {
            initial: "visible",
            states: {
              visible: {
                on: {
                  HIDE_MAP: "hidden",
                },
              },
              hidden: {
                entry: "offsetVanishingPoint",
                exit: "restoreVanishingPoint",
              },
            },
          },
        },
      },

      view: {
        id: "view",
        initial: "init",
        states: {
          init: {},

          overview: {
            initial: "idle",
            on: {
              SHOW_PROJECT_POPUP: {
                target: ".projectPopup",
                actions: "selectProjectPopup",
              },

              GO_TO_PROJECT: {
                target: "transitioningToProject",
                actions: "selectProject",
              },
            },
            states: {
              idle: {},

              projectPopup: {
                on: {
                  HIDE_PROJECT_POPUP: {
                    target: "idle",
                  },
                },
              },
            },
          },

          transitioningToProject: {
            on: {
              GO_TO_OVERVIEW: {
                target: [
                  "#mounted.mounted.visible",
                  "#view.transitioningToOverview",
                ],
              },
            },
            after: {
              [mapViewTransitionDurationInMs]: "project",
            },
          },

          transitioningToOverview: {
            on: {
              GO_TO_OVERVIEW: {
                target: [
                  "#mounted.mounted.visible",
                  "#view.transitioningToOverview",
                ],
              },
            },
            after: {
              [mapViewTransitionDurationInMs]: "overview",
            },
          },

          project: {
            id: "project",
            initial: "noLotSelected",
            on: {
              GO_TO_OVERVIEW: {
                target: [
                  "#mounted.mounted.visible",
                  "#view.transitioningToOverview",
                ],
              },
            },
            states: {
              noLotSelected: {
                on: {
                  SELECT_LOT: { target: "lotSelected", actions: "selectLot" },
                },
              },

              lotSelected: {
                on: {
                  DESELECT_LOT: "#project.noLotSelected",
                },
              },
            },
          },
        },
      },
    },
  },

  {
    actions: {
      selectProject: assign({
        activeProjectId: (_, event) => {
          if ("projectId" in event) {
            return event?.projectId;
          }
        },
      }),

      selectProjectPopup: assign({
        activeProjectPopupId: (_, event) => {
          if ("projectId" in event) {
            return event?.projectId;
          }
        },
      }),

      selectLot: assign({
        selectedLotId: (_, event) => {
          if ("lotId" in event) {
            return event?.lotId;
          }
        },
      }),
    },
  }
);
