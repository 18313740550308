import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { Narrow } from "./narrow";
import { Wide } from "./wide";

/**
 * Nav
 */
export const Nav: FC = () => {
  return (
    <>
      <Box as="nav" display={{ base: "block", md: "none" }}>
        <Narrow />
      </Box>

      <Box as="nav" display={{ base: "none", md: "block" }}>
        <Wide />
      </Box>
    </>
  );
};
