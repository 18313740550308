import React, { FC } from "react";
import {
  Flex,
  Spacer,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import { WideNavQuery } from "../../../graphql-types";
import { useLocation } from "@reach/router";
import Logo from "../../assets/svg/logo.inline.svg";
import { AnimateSharedLayout, motion } from "framer-motion";
import { Container } from "../container";

export const Wide: FC = () => {
  const data = useStaticQuery<WideNavQuery>(graphql`
    query WideNav {
      wpMenu(locations: { eq: PRIMARY }) {
        menuItems {
          nodes {
            id
            parentId
            label
            url
          }
        }
      }
    }
  `);

  const location = useLocation();

  return (
    <Container maxW="xl" hasGutter={false} py={6} color="white">
      <Flex align="baseline">
        <ChakraLink as={GatsbyLink} to="/" style={{ pointerEvents: "all" }}>
          <Logo />
        </ChakraLink>

        <Spacer />

        <Stack direction="row" spacing={16} pointerEvents="all">
          <AnimateSharedLayout>
            {data.wpMenu?.menuItems?.nodes
              ?.filter(node => !node?.parentId)
              .map(node => {
                const children = data.wpMenu?.menuItems?.nodes?.filter(
                  potentialChildNode =>
                    potentialChildNode?.parentId === node?.id
                );

                const isActive = node?.url === location.pathname;

                if (children?.length) {
                  return (
                    <Menu key={node?.id} placement="bottom">
                      <MenuButton
                        as={Button}
                        py={2}
                        variant="unstyled"
                        rightIcon={<ChevronDownIcon />}
                        fontWeight="medium"
                        h="auto"
                      >
                        {node?.label}
                      </MenuButton>
                      <MenuList m={"0 !important"}>
                        {children?.map(childNode => (
                          <MenuItem
                            as={GatsbyLink}
                            color="tundora"
                            _hover={{ color: "forestGreen" }}
                            key={childNode?.id}
                            to={childNode?.url || ""}
                          >
                            {childNode?.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  );
                }

                return (
                  <ChakraLink
                    key={node?.id}
                    as={GatsbyLink}
                    py={2}
                    to={node?.url || ""}
                    _hover={{
                      ...(!isActive && { transform: "translateY(-2px)" }),
                    }}
                    transition="all .1s ease-in-out"
                    fontWeight="medium"
                    position="relative"
                  >
                    {node?.label}

                    {isActive && (
                      <motion.div
                        layoutId="menuItem"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          width: "40%",
                          borderBottom: "2px solid #fff",
                        }}
                      />
                    )}
                  </ChakraLink>
                );
              })}
          </AnimateSharedLayout>
        </Stack>
      </Flex>
    </Container>
  );
};
