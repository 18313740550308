import React, { FC } from "react";
import {
  ContainerProps,
  Container as ChakraContainer,
  SystemStyleObject,
} from "@chakra-ui/react";

type Props = {
  hasGutter?: boolean;
} & ContainerProps;

export const Container: FC<Props> = props => {
  const { hasGutter = true, ...rest } = props;

  const gutterWidth = "1rem";

  const styles: SystemStyleObject = {
    w: hasGutter ? `calc(100% - (2 * ${gutterWidth}))` : "100%",
    px: hasGutter ? gutterWidth : 0,
    boxSizing: "content-box",
  };

  return <ChakraContainer __css={styles} {...rest} />;
};
